<template>
    <div class="main">
        <div class="text-h3">
            保险业务
        </div>
        <v-divider></v-divider>
        <v-card
                class="mx-auto card"
                style="margin-top: 30px; padding: 20px"
        >
            <v-card-title>
                <v-icon
                        large
                        left
                >
                    mdi-home-floor-b
                </v-icon>
                <span class="title font-weight-light">基础信息</span>
            </v-card-title>
            <v-row>
                <v-col md="3" cols="12" sm="12">
                    <v-menu
                            ref="menu"
                            :close-on-content-click="false"
                            :return-value.sync="month"
                            transition="scale-transition"
                            offset-y
                            min-width="290px"
                    >
                        <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                                    v-model="month"
                                    label="月份"
                                    prepend-icon="mdi-calendar"
                                    readonly
                                    dense
                                    outlined
                                    v-bind="attrs"
                                    v-on="on"
                            ></v-text-field>
                        </template>
                        <v-date-picker
                                v-model="month"
                                no-title
                                type="month"
                                scrollable
                                @input="$refs.menu.save(month);"
                        >
                        </v-date-picker>
                    </v-menu>
                </v-col>
                <v-col md="3" cols="12" sm="12">
                    <v-menu
                            ref="menu"
                            :close-on-content-click="false"
                            :return-value.sync="date"
                            transition="scale-transition"
                            offset-y
                            min-width="290px"
                    >
                        <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                                    v-model="date"
                                    label="出单日期"
                                    prepend-icon="mdi-calendar"
                                    readonly
                                    dense
                                    outlined
                                    v-bind="attrs"
                                    v-on="on"
                            ></v-text-field>
                        </template>
                        <v-date-picker
                                v-model="date"
                                no-title
                                scrollable
                                @input="$refs.menu.save(date);"
                        >
                        </v-date-picker>
                    </v-menu>
                </v-col>

            </v-row>
            <v-row>
                <v-col md="3" cols="12" sm="12">
                    <v-text-field
                            outlined
                            dense
                            label="分公司"
                    ></v-text-field>
                </v-col>
                <v-col md="3" cols="12" sm="12">
                    <v-text-field
                            outlined
                            dense
                            label="区域"
                    ></v-text-field>
                </v-col>
                <v-col md="3" cols="12" sm="12">
                    <v-text-field
                            outlined
                            dense
                            label="服务网点（专营店）"
                    ></v-text-field>
                </v-col>
                <v-col md="3" cols="12" sm="12">
                    <v-text-field
                            outlined
                            dense
                            label="新保/续保"
                    ></v-text-field>
                </v-col>
                <v-col md="3" cols="12" sm="12">
                    <v-text-field
                            outlined
                            dense
                            label="客户姓名"
                    ></v-text-field>
                </v-col>
                <v-col md="3" cols="12" sm="12">
                    <v-text-field
                            outlined
                            dense
                            label="车牌号"
                    ></v-text-field>
                </v-col>
                <v-col md="3" cols="12" sm="12">
                    <v-text-field
                            outlined
                            dense
                            label="VIN码
                            （完整填写）"
                    ></v-text-field>
                </v-col>
                <v-col md="3" cols="12" sm="12">
                    <v-text-field
                            outlined
                            dense
                            label="保险公司"
                    ></v-text-field>
                </v-col>
                <v-col md="3" cols="12" sm="12">
                    <v-text-field
                            outlined
                            dense
                            label="签单保费-商业险"
                    ></v-text-field>
                </v-col>
                <v-col md="3" cols="12" sm="12">
                    <v-text-field
                            outlined
                            dense
                            label="签单保费-交强险"
                    ></v-text-field>
                </v-col>
                <v-col md="3" cols="12" sm="12">
                    <v-text-field
                            outlined
                            dense
                            label="其他险种"
                    ></v-text-field>
                </v-col>
            </v-row>
        </v-card>
        <v-btn style="margin-top: 20px" color="primary">提交</v-btn>
    </div>
</template>
<script>
    export default  {
        name: 'InsuranceList',
        data: () => ({
            date: '',
            month: ''
        })
    }
</script>
<style lang="scss" scoped>

</style>
